
import React from 'react';
import './Home.scss';

const Home = () => {

    return (
        <div className="home_container">
            <div className="home_content home_right_container">
                <h1>Czym jest Wspinacznik?</h1>
            </div>
            <div className="home_content home_my_list">
                <h1>Baza ścian wspinaczkowych</h1>
            </div>
        </div>
    );
};

export default Home;