import React, {Component} from 'react';
import './Header.scss';
import {Link} from "react-router-dom";
import logo from '../../../assets/logo.png';

class Header extends Component {

    render() {
        return (
            <div className="Header">
                <div className="Header_Container">
                    <Link to="/">
                        <img className="Header_Container_Logo" src={logo} alt="Trawersem.pl"/>
                    </Link>
                    {!this.props.user && <Link to="/login" className="Header_Login_Button">Logowanie</Link>}
                </div>
            </div>
        );

    }

}

export default Header;