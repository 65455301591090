import React, {Component} from 'react';
import './App.scss';
import {BrowserRouter, Route} from "react-router-dom";
import Header from "../Common/Header/Header";
import Menu from "../Common/Menu/Menu";
import Home from "../Home/Home";
import Grades from "../Grades/Grades";

class App extends Component {


    render() {
        return (
            <BrowserRouter>
                <div className="AppContainer">
                    <Header user={this.state ? this.state.user : {}}/>
                    <Menu isUserLogged={Boolean(this.state && this.state.user)}/>
                    {this.renderRouting()}
                </div>
            </BrowserRouter>

        );
    }

    renderRouting = () => {
        return (
            <React.Fragment>
                <Route exact path="/" render={(props) => <Home {...props} />}/>
                <Route exact path="/skale" render={(props) => <Grades {...props} />}/>
                {/*<Route exact path="/nowekonto" render={(props) => <Login {...props} />}/>*/}
            </React.Fragment>
        );
    };
}

export default App;