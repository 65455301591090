import React, {useEffect, useState} from 'react';
import './Grades.scss';

const Grades = () => {

    const [grades, setGrades] = useState();

    useEffect(() => {
        if (!localStorage.getItem(process.env.GRADES_STORAGE) && !grades) {
            fetch(process.env.REACT_APP_API_URL + "/grades", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_API_KEY
                }
            }).then((response) => {
                return response.json();
            }).then((json) => {
                localStorage.setItem(process.env.GRADES_STORAGE, JSON.stringify(json));
                setGrades(json);
            }).catch((err) => {
                console.log(err);
            });
        } else if (!grades) {
            setGrades(JSON.parse(localStorage.getItem(process.env.GRADES_STORAGE)));
        }
    }, [grades]);

    return (
        <div className="grades_container">
            <div className="grades_content grades_right_container">
                <h1>Tabela wycen wspinaczkowych (letnich)</h1>
                <table>
                    <tbody>
                    <tr>
                        <th>UIAA</th>
                        <th>Kurtyki</th>
                        <th>Francuska</th>
                        <th>USA</th>
                    </tr>
                    {grades && grades.map(item =>
                        <tr key={item.grade_id}>
                            <td>{item.grade_uiaa}</td>
                            <td>{item.grade_kurtyka}</td>
                            <td>{item.grade_fr}</td>
                            <td>{item.grade_us}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Grades;