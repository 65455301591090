import React, {Component} from 'react';
import './Menu.scss';
import {Link} from "react-router-dom";
import hamburger from "../../../assets/hamburger.png";

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuVisible: true
        };
    }

    render() {
        return (
            <div>
                <div className="Menu_Button">
                    <img src={hamburger} alt="Menu" className="Menu_Button_Hamburger"
                         onClick={() => this.handleHamburgerClick()}/>
                </div>
                {
                    (this.state.isMenuVisible) &&
                    <div className="Menu">
                        <Link to="/" className={`Menu_Item`}>Home</Link>
                        {/*<Link to="/wspinacznik" className={`Menu_Item`}>Wspinacznik</Link>*/}
                        <Link to="/skale" className={`Menu_Item`}>Tabela wycen</Link>
                        {/*<Link to="/profil" className={`Menu_Item ${!this.props.isUserLogged && "inactive"}`}>Moje wspiny</Link>*/}
                        {/*<Link to="/profil" className={`Menu_Item ${!this.props.isUserLogged && "inactive"}`}>Konto</Link>*/}
                    </div>
                }
            </div>
        );
    }

    handleHamburgerClick = () => {
        this.setState({isMenuVisible: !this.state.isMenuVisible});
    }
}

export default Menu;